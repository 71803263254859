import { createSlice } from '@reduxjs/toolkit';
import { STEPS } from '../../../global-constants';

const INITIAL_STATE = {
    activeStep: '',
    disabledStep: [STEPS.DISTRIBUTE_SURVEY, STEPS.SURVEY_SETUP, STEPS.RESPONDENT],
    unsavedQuestError: false,
    showUnsavedError: false,
    pulseQuestionSelectError: false,
    showPulseQuestionSelectError: false,
};

const ActiveStep = createSlice({
    name: 'activeStep',
    initialState: INITIAL_STATE,
    reducers: {
        setActiveStep: (state, { payload }) => {
            state.activeStep = payload;
        },
        setDisabledStep: (state, { payload }) => {
            state.disabledStep = payload;
        },
        setUnsavedQuestError: (state, { payload }) => {
            state.unsavedQuestError = payload;
        },
        setShowUnsavedError: (state, { payload }) => {
            state.showUnsavedError = payload;
        },
        setPulseQuestSelectError: (state, { payload }) => {
            state.pulseQuestionSelectError = payload;
        },
        setShowPulseQuestionSelectError: (state, { payload }) => {
            state.showPulseQuestionSelectError = payload;
        },
    },
});

export const {
    setActiveStep,
    setDisabledStep,
    setUnsavedQuestError,
    setShowUnsavedError,
    setPulseQuestSelectError,
    setShowPulseQuestionSelectError,
} = ActiveStep.actions;
export default ActiveStep.reducer;
