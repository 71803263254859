import React from 'react';
import { commonThemes } from '../themes';
import { convert } from '../functions';
import { RangeDatePicker, SIZE_MEDIUM, ThemeProvider } from '@mds/mds-reactjs-library';
import { colorsCode, pxToVh, pxToVw } from '../colors';
import styled from '@emotion/styled';
import moment from 'moment';
import { convertDateToTimestamp } from '../../utils/functions';

const DateRangePicker = ({ OnDateChangeFn, startDate, setStartDate, endDate, setEndDate }) => {
    const styles = commonThemes();

    const changeRange = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            OnDateChangeFn(convert(start), convert(end));
        }
    };

    const customDatePickerTheme = {
        forms: {
            datePicker: {
                font: { size: { default: '0.8rem', month: '1rem' } },
                color: {
                    border: { default: 'red' },
                    background: {
                        default: '#ffffff',
                        selected: colorsCode.PrimaryDeepBlue,
                        'in-range': '#E6F2F9',
                        hover: '#E6F2F9',
                        'in-range-hover': '#E6F2F9',
                        'selected-hover': colorsCode.PrimaryDeepBlue,
                    },
                },
            },
        },
    };

    return (
        <div className={`${styles.dateRangePicker}`}>
            <ThemeProvider customTheme={customDatePickerTheme}>
                <DateDiv
                    startDate={startDate}
                    endDate={endDate}
                    value={[
                        moment(convertDateToTimestamp(startDate)).format('DD/MM/YYYY'),
                        moment(convertDateToTimestamp(endDate)).format('DD/MM/YYYY'),
                    ]}
                    onChange={changeRange}
                    placeholderText="DD/MM/YYYY"
                    size={SIZE_MEDIUM}
                    title="date"
                />
            </ThemeProvider>
        </div>
    );
};

const DateDiv = styled(RangeDatePicker)`
    height: ${pxToVh(32)}vh !important;
    min-width: ${pxToVw(275)}vw !important;
    border-radius: 4px;
    input {
        font-size: ${pxToVh(14)}vh !important;
        line-height: ${pxToVh(24)}vh;
        &:focus {
            border-bottom: 4px solid #fff !important;
        }
    }
`;

export default DateRangePicker;
